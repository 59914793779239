<template>
  <TopNavBar />

  <!-- breadcrumb area start -->
  <div
    class="breadcrumb-area"
    style="background-image: url(assets/img/page-title-bg.png)"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="breadcrumb-inner">
            <h1 class="page-title">News</h1>
            <ul class="page-list">
              <li>
                <router-link class="nav-link" :to="{ name: 'home' }"
                  >Home</router-link
                >
              </li>
              <li>News</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- breadcrumb area End -->

  <!-- about area start -->
  <div class="about-provide-area pd-top-120 bg-none">
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <div class="section-title style-two">
            <h2 class="title">Our <span>news</span></h2>
          </div>
        </div>
        <div class="col-lg-9 columnsArticles">
          <div v-if="articlesIsLoading">Loading...</div>

          <div
            v-else
            v-for="article in articles"
            :key="article.id"
            class="single-blog-content single-service style-two"
          >
            <div class="thumb mb-0">
              <img :src="article.thumbnail.url" :alt="article.title" />
            </div>
            <div class="single-blog-details">
              <h5>{{ article.title }}</h5>
              <div class="published_on">
                Published on:
                {{ convertFirestoreTimestampToDate(article.publish_on) }}
              </div>
              <p v-html="article.description.replace(/\\n/g, '')"></p>
              <div v-if="'attachment' in article">
                <strong>Attachment</strong>
                <ul class="list-style-none" id="attachments">
                  <li>
                    <a :href="article.attachment.url" target="_blank"
                      ><i class="fa fa-file-pdf-o"></i>
                      {{ article.attachment.name }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- about area End -->

  <LetsTalk />
  <Footer />

  <!-- back to top area start -->
  <div class="back-to-top">
    <span class="back-top"><i class="fa fa-angle-up"></i></span>
  </div>
  <!-- back to top area end -->
</template>

<script>
import TopNavBar from "@/components/TopNavBar.vue";
import LetsTalk from "@/components/LetsTalk.vue";
import Footer from "@/components/Footer.vue";
import { mapGetters } from "vuex";
import { format } from "date-fns";

export default {
  name: "Home",
  components: {
    TopNavBar,
    LetsTalk,
    Footer,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      articles: "articles/articles",
      articlesIsLoading: "articles/isLoading",
    }),
  },
  methods: {
    convertFirestoreTimestampToDate(timestamp, formatString = "EEE dd MMM yy") {
      if (!timestamp) return "-";
      const date = timestamp.toDate();
      return format(date, formatString);
    },
  },
};
</script>

<style scoped>
.list-style-none {
  list-style: none;
}

#attachments a {
  margin-top: 0;
  color: var(--main-color-one);
}

.columnsArticles {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  height: auto;
  align-items: flex-start;
}

.published_on {
  color: #01358d;
  padding-bottom: 1rem;
}
</style>

<style>
.columnsArticles .section-title a {
  margin-top: 0 !important;
}
</style>
